<script>
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
export default {
  name: "dashboard-pbx",
  components: {
    datePicker,
  },
  data: function() {
    return {
      isLoading: false,
      currentPage: 1,
      startAt: undefined,
      endAt: undefined,
      results: [],
      columns: [
        { label: "Хаанаас", key: "caller_name" },
        { label: "Хаашаа", key: "dst" },
        { label: "Он/Сар", key: "dcontext", class: "text-left" },
        { label: "Он/Сар", key: "calldate" },
        { label: "Төлөв", key: "disposition" },
        { label: "Хугацаа", key: "duration" },
      ],
      registers: [],
      selected_operator: {
        name: "Ажилтан сонгох",
      },
      param: {
        caller: undefined,
        numRecords: "2000",
        startTime: "2019-09-01",
        endTime: "2019-09-30",
      },
      operators: [
        {
          name: "Бүгд",
          operator_number: undefined,
          phone_number: "",
        },
        {
          name: "Service Bavaria",
          operator_number: 1501,
          phone_number: "",
        },
        {
          name: "Spare Parts",
          operator_number: 1502,
          phone_number: "",
        },
        {
          name: "Welcome Desk",
          operator_number: 1504,
          phone_number: "",
        },
        {
          name: "Numur Office",
          operator_number: 1505,
          phone_number: "",
        },
        {
          name: "Sales Bavaria",
          operator_number: 1506,
          phone_number: "",
        },
        {
          name: "Sales Sales2	",
          operator_number: 1507,
          phone_number: "",
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function() {
      this.$data.isLoading = true;
      this.$http
        .get("https://admin.numur.mn/pbx/get.php", {
          params: this.$data.param,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.isLoading = false;
            if (response.status == 200) {
              this.$data.isLoading = false;
              this.$data.results = [];
              //console.log(response.body)
              for (var i in response.body.data) {
                var item = response.body.data[i];
                this.$data.results.push({
                  caller_name: this.findOpertorData(item.action_owner),
                  dst: this.findOpertorData(item.dst),
                  disposition: item.disposition,
                  dcontext: item.dcontext,
                  calldate: item.calldate,
                  duration: item.duration,
                });
                //console.log(item)
              }
            }
          },
          (response) => {
            this.$data.isLoading = false;
          }
        );
    },
    findOpertorData: function(operator_number) {
      for (var i in this.$data.operators) {
        var item = this.$data.operators[i];
        if (
          item.operator_number == operator_number ||
          item.phone_number == operator_number
        ) {
          return item.name;
        }
      }
      return operator_number;
    },
    selectedOperator: function(item) {
      this.$data.selected_operator = item;
      this.$data.param.caller = item.operator_number;
    },
    submitSearch: function() {
      this.loadData();
    },
  },
};
</script>

<template>
  <div class="animated fadeIn">
    <b-card>
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>

      <div class="mb-3 clearfix">
        <b-dropdown
          id="dropdown-1"
          :text="selected_operator.name"
          class="pull-left"
        >
          <b-dropdown-item
            v-for="(item, index) in operators"
            :key="index"
            @click="selectedOperator(item)"
            >{{ item.name }}</b-dropdown-item
          >
        </b-dropdown>

        <b-form @submit.stop.prevent="submitSearch" class="col-md-5 pull-left">
          <b-input-group>
            <b-input-group-prepend is-text
              ><i class="fa fa-calendar"></i
            ></b-input-group-prepend>
            <date-picker
              v-model="param.startTime"
              :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
              placeholder="Эхлэх огноо"
            ></date-picker>
            <date-picker
              v-model="param.endTime"
              :config="{ format: 'YYYY-MM-DD', useCurrent: false }"
              placeholder="Дуусах огноо"
            ></date-picker>
            <b-input-group-append
              ><b-button variant="primary" type="submit"
                >Шүүх</b-button
              ></b-input-group-append
            >
          </b-input-group>
        </b-form>
      </div>

      <b-table :small="true" :items="results" :fields="columns">
        <template slot="isAccepted" slot-scope="data">
          <b-badge variant="success" v-if="data.item.isAccepted == 'YES'"
            >Тийм</b-badge
          >
          <b-badge variant="danger" v-else>Үгүй</b-badge>
        </template>
        <template slot="date" slot-scope="data">
          {{ data.item.year }}/{{ data.item.month }}/{{ data.item.day }}
        </template>

        <template slot="dcontext" slot-scope="data">
          <b-badge
            variant="success"
            v-if="data.item.dcontext == 'from-did-direct'"
            >ОРЖ ИРСЭН</b-badge
          >
          <b-badge variant="danger" v-else>ГАРСАН</b-badge>
        </template>

        <template slot="disposition" slot-scope="data">
          <b-badge variant="success" v-if="data.item.disposition == 'ANSWERED'"
            >ЯРЬСАН</b-badge
          >
          <b-badge
            variant="danger"
            v-else-if="data.item.disposition == 'NO ANSWER'"
            >АВААГҮЙ</b-badge
          >
          <b-badge variant="danger" v-else>ЗАВГҮЙ</b-badge>
        </template>

        <template slot="duration" slot-scope="data">
          <span v-if="parseInt(data.item.duration / 60) > 0">
            {{ parseInt(data.item.duration / 60) }}мин
          </span>
          <span
            v-if="
              parseInt(data.item.duration / 60) > 0 &&
                parseInt(data.item.duration % 60) > 0
            "
          >
            :
          </span>
          <span v-if="parseInt(data.item.duration % 60) > 0">
            {{ data.item.duration % 60 }}сек
          </span>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="10"
        :per-page="10"
        aria-controls="my-table"
      >
      </b-pagination>
    </b-card>
  </div>
</template>
